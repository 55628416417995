import React from "react"
import { Color, Rectangle } from "cesium";
import { CameraFlyTo } from "resium";

// const AucklandBounds  = Rectangle.fromDegrees(174.62384304791584, -36.93864277499035, 174.93819, -36.84117756577367)
// const WellingtonBounds   = Rectangle.fromDegrees(174.65097109187406, -41.397492440804004, 175.1473878714578, -41.09238796708955)
const NZBounds = Rectangle.fromDegrees(165.9, -47.24, 178, -34.31)
const TongaBounds = Rectangle.fromDegrees(-176.21821150724102, -22.351062698447706, -174.850344561256, -15.98224615456446)
const orientation = null

const my_date = new Date()

const config = {
    api_url: "https://kscprod-bio9.esr.cri.nz:50585",
    // api_url: 'https://dt-pioneer.azurewebsites.net/',
    // api_url: "https://esr-digital-twin-api-dev2.azurewebsites.net",
    dev_api_url: "https://esr-digital-twin-api-dev.azurewebsites.net",
    // dev_api_url: "https://esr-digital-twin-api-dev2.azurewebsites.net",
    // dev_api_url: "http://localhost:3000",
    chatbot_url: "https://kscprod-data3.esr.cri.nz:28003",
    api_keys: {
        googlemaps: "AIzaSyAH128P_UVaOjv2xWgoryxj6B8jQK8Pe78",
        cesium: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNmEwYjcyZi0yMWM5LTRiNDctYmM3MS1jZWJlZWY0NmRkNmEiLCJpZCI6MTU4ODIxLCJpYXQiOjE2OTI4NDI4Mjl9.jOUM0oTCyPoiB_qadbDbl7dQrmZ1DSMTa2om-m6mgIs",
        esri_arcgis_geolocation: "AAPKb88b5a12540e467c89dbb4f35578eeb1rQvu0oV9QXMR7xMtVgFBIAnZdEu6ZGKXbfWBClFmwOwH5bLg2xh9qcDfN7uzJ0uU",
        esri_arcgis_basemaps: "AAPTxy8BH1VEsoebNVZXo8HurMvGd6RYkoIF7zyC87YVpZCQidTA-FvfpPxyqqahx3NaoQ9iF_5GDm0v97cP79sI8lHjPK0YjPKjjWgVSSUttEzMfmH7tBbKBmmccRl0JBY557sMnkf2Bvg63ypGYaF16jtbPpcuuxaqaKgMaeZYp2U_Vf0WMyZVbvEC-lBGej1wDnxG_rvd2W1t7082U8dH390IRw52aRgS96gdQFBoCy4.AT1_1cQUjXF3",
    },
    defaultCameraPosition: <CameraFlyTo
        duration={0} 
        destination={NZBounds} 
        orientation={orientation} 
        />,
    map_date: {
        // date: my_date,
        startTime: my_date.toJSON().slice(0, 10) + "T00:00:00Z",
        stopTime:  my_date.toJSON().slice(0, 10) + "T23:59:59Z",
        currentTime: my_date.toJSON().slice(0, 10) + "T08:00:00Z",
    },
    twins: {
        'nz': {
            id: 'nz',
            name: "New Zealand",
            default_camera_destination: NZBounds,
            default_camera_orientation: null,
        },
        'tonga': {
            id: 'tonga',
            name: "Tonga",
            default_camera_destination: TongaBounds,
            default_camera_orientation: null,
        }
    },
    location_types: {
        default: {
            htmlColour: "PURPLE",
            cesiumColour: Color.PURPLE,
            plural: 'DEFAULT',
            radius_multiplier: 1,
        },
        cafe: {
            htmlColour: "OLIVE",
            cesiumColour: Color.OLIVE,
            plural: 'cafes',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
        company: {
            htmlColour: "AQUA",
            cesiumColour: Color.AQUA,
            plural: 'companies',
            radius_multiplier: 1.2,
        },
        department_store: {
            htmlColour: "DARKBLUE",
            cesiumColour: Color.DARKBLUE,
            plural: 'Department stores',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
        fast_food: {
            htmlColour: "BROWN",
            cesiumColour: Color.BROWN,
            plural: 'Fast food restaurants',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
        household: {
            htmlColour: "YELLOW",
            cesiumColour: Color.YELLOW,
            plural: 'households',
            radius_multiplier: .9,
            fly_to_default_height: 75, //metres
        },
        kindergarten: {
            htmlColour: "HOTPINK",
            cesiumColour: Color.HOTPINK,
            plural: 'kindergartens',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
        park: {
            htmlColour: "FORESTGREEN",
            cesiumColour: Color.FORESTGREEN,
            plural: 'parks',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
        pharmacy: {
            htmlColour: "DARKGOLDENROD",
            cesiumColour: Color.DARKGOLDENROD,
            plural: 'pharmacies',
            radius_multiplier: 2,
        },
        pub: {
            htmlColour: "CRIMSON",
            cesiumColour: Color.CRIMSON,
            plural: 'pubs',
            radius_multiplier: 2,
        },
        restaurant: {
            htmlColour: "ORANGE",
            cesiumColour: Color.ORANGE,
            plural: 'restaurants',
            radius_multiplier: 2,
        },
        school: {
            htmlColour: "MAGENTA",
            cesiumColour: Color.MAGENTA,
            plural: 'schools',
            radius_multiplier: 3,
            fly_to_default_height: 250, //metres
        },
        hospital: {
            htmlColour: "RED",
            cesiumColour: Color.RED,
            plural: 'hospitals',
            radius_multiplier: 2,
            fly_to_default_height: 300, //metres
        },
        supermarket: {
            htmlColour: "BLUE",
            cesiumColour: Color.BLUE,
            plural: 'supermarkets',
            radius_multiplier: 4,
            fly_to_default_height: 300, //metres
        },
        wholesale: {
            htmlColour: "BLACK",
            cesiumColour: Color.BLACK,
            plural: 'wholesalers',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
        XXXX: {
            htmlColour: "ORANGE",
            cesiumColour: Color.ORANGE,
            plural: 'XXXX',
            radius_multiplier: 2,
            fly_to_default_height: 200, //metres
        },
    },
    tours: {
        default: {
            steps: [
                
                {
                    target: '#selectData',
                    title: 'Data Explorer',
                    content: <div>
                        <p>
                            The Data Explorer is where you can select data and models to load in to the Digital Twin.
                        </p>
                    </div>,
                    disableBeacon: true,
                    placement: 'bottom-end',
                },
                {
                    target: '.cesium-animation-shuttleRingBack',
                    title: 'Clock controls',
                    content: <div>
                        <p>Use the play and pause buttons to animate models through time.</p>
                        <p>Drag the grey arrow clockwise to speed up the animation.</p>
                    </div>,
                    disableBeacon: true,
                    placement: 'top',
                },
                {
                    target: '.cesium-baseLayerPicker-selected',
                    title: 'Map baselayer',
                    content: <div>
                        <p>
                            Switch the basemap between LINZ maps of New Zealand, Bing road and satellite maps, or OpenStreetMap.
                        </p>
                        <p>
                            Google photorealistic 3D mapping tiles are available for some urban areas.
                        </p>
                    </div>,
                    disableBeacon: true,
                },
                {
                    target: '#askChatbotLink',
                    title: 'Chatbot',
                    content: <div>
                        <p>We're developing a chatbot to help you ask questions of the data and navigate around.</p>
                        <p>To get started, click the <b>Ask</b> link.</p>
                        <p>For now, you can ask it for the weather:</p>
                        <div  style={{
                                padding: '3px',
                                background: '#CCCCCC',
                                fontFamily: "courier"
                            }}>
                                Weather in Porirua
                        </div>
                        <p>Or ask it to zoom to a location:</p>
                        <div style={{
                                padding: '3px',
                                background: '#CCCCCC',
                                fontFamily: "courier"
                            }}>Fly to Queenstown
                        </div>
                    </div>,
                    disableBeacon: true,
                },

                //   
            ]
          }
    }
}

export default config;