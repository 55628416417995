/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from "react"
import { Alert, Button, Card, CardBody, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import useDataStore from "../helpers/dataStore"
import config from "../helpers/config"
import { CameraFlyTo } from "resium"
import { Cartesian3, Rectangle } from "cesium"
import axios from "axios"
import MapABMDiaries from "./MapABMDiaries"
import MapABMJourneyLines from "./MapABMJourneyLines"
import {
    LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart,
  } from 'recharts';
import MapABMRouting from "./MapABMRouting"
import { Gear, GearFill } from "react-bootstrap-icons"

const LeftMenuABM = ({model_id}) => {
    const myStore = useDataStore()
    const [expanded_location_types, setExpandedLocationTypes] = useState([])
    const [loadingAgents, setLoadingAgents] = useState(false)
    const [loadingAgent, setLoadingAgent] = useState(null)
    const [agent_demographics, setAgentDemographics] = useState(null)
    const [agent_locations, setAgentLocations] = useState(null)

    const loadAgents = () => {
        console.log("Loading agent diaries between these bounds for model_id {model_id}")
        setLoadingAgents(true)

        const bounds = myStore.map_viewport?.bounds
        if (! bounds) {
            return
        }

        myStore.fnSetKeyValue('cameraPosition', null) // unset any camera positions)

        console.log("BOUNDS:", bounds)
        
        const url = `${config.dev_api_url}/agents_in_bounds${( myStore?.abm_urls ? myStore.abm_urls : '' )}/${model_id}?minLng=${bounds.west}&minLat=${bounds.south}&maxLng=${bounds.east}&maxLat=${bounds.north}&limit=1000`
        console.log(`fetching`,url)

        axios
            .get(url, myStore.api_axios_header)
            .then((result) => {
                myStore.fnCallback((state) => {
                    state.abm_diaries = result.data
                    state.map_layers = {
                        'abm_diaries': <MapABMDiaries />,
                        'abm_journeys': <MapABMJourneyLines />
                    }
                    state.map_show_clock = true
                    setLoadingAgents(false)
                })
            })
            .catch((err) => {
                console.error('ERROR', err)
                setLoadingAgents(false)
            })
    }

    const SA2Info = useMemo(() => {
        if (! myStore.selected_areas.sa2.length) {
            return () => <Container>Click on the map of New Zealand to select a suburb - or use the search box above.</Container>
        }

        const sa2_elements = myStore.selected_areas.sa2.map((sa2, ix) => {
            return <Row key={`sa2_left_${ix}`}>
                <Col>
                    <span style={{fontWeight:'bolder'}}>{sa2.area_name}</span>
                    {' '}
                    <span style={{fontWeight:'lighter', color:'#434343', fontSize:'.9em'}}>{sa2.area_code}</span>    
                    {' '}
                    <Button 
                        size='sm'
                        variant='secondary'
                        onClick={() => { myStore.fnCallback((state) => { 
                            state.selected_areas.sa2 = state.selected_areas.sa2.filter((area, sx) => { area.area_code != sa2.area_code })
                            state.abm_locations = []
                            })}}>
                        Clear SA2
                    </Button>
                </Col>
            </Row>
        }) 

        return () => <Container>
            {sa2_elements}
            </Container>

    }, [myStore.selected_areas.sa2])


    const hourlyCountChart = useMemo(() => {
        const location = myStore?.abm_selected_location
        const data = location?.hourly_counts
        if (! data) {
            return null
        }

        return <ComposedChart
          width={360}
          height={200}
          data={data}
          margin={{
            // top: 20, right: 30, left: 20, bottom: 5,
            top: 0, right: 0, left:0 , bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hour" />
          <YAxis width={50} />
          <Tooltip />
          <Legend />
          <Bar dataKey="just_arrived" fill="#2EA2F5" name="Arrivals" />
          <Bar dataKey="just_left" fill="#953FBA" name="Departures" />
          <Line type="monotone" dataKey="num_agents" stroke="#126B5D" name="Total Onsite" strokeWidth={2} />
        </ComposedChart>

    },[myStore?.abm_selected_location])

    const SelectedLocation = useMemo(() => {
        const location = myStore?.abm_selected_location
        if (! location) {
            return () => null
        }

        console.log("LOCATION'", location)

        return () => <Container>
            <Row>
                <Col>
                    <strong>{`${(location.location_name ? location.location_name : location.location_id)} (${location.location_type})`}</strong>
                    <Button 
                        size='sm'
                        variant='secondary'
                        onClick={() => { myStore.fnSetKeyValue('abm_selected_location', null)}}>
                        Clear location
                    </Button>
                    <p>{location.location_id}</p>
                    {
                        location?.status == 'loading'
                        &&
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }
                    {
                        hourlyCountChart
                    }
                </Col>
            </Row>

        </Container>

    }, [myStore?.abm_selected_location])

    const Locations = useMemo(() => {
        if (myStore.abm_locations == false) {
            return () => null
        }
        
        const LocationElements = []

        LocationElements.push(<div>{`${myStore.abm_locations.length} locations:`}</div>)
        const LocationList = []
        const LocationTypeCounts = {}

        // console.log("LOCATIONS IN MYSTORE", myStore.abm_locations)

        myStore.abm_locations.map((location,i) => {
            // const location = v[1]
            LocationTypeCounts[location.location_type] = (
                LocationTypeCounts[location.location_type] ? LocationTypeCounts[location.location_type] += 1 : 1)
        })

        if (Object.keys(LocationTypeCounts).length > 0) {
            for (const location_type_key in LocationTypeCounts) {

                var location_type = config.location_types[location_type_key]
                if (! location_type) {
                    location_type = config.location_types['default']
                    location_type['plural']=location_type_key
                }

                LocationElements.push(
                    <div
                        key={`lt_${location_type_key}`}
                        style={{
                            'borderLeft':`20px solid ${location_type.htmlColour}`,
                            'padding': '5px',
                            'marginBottom': '2px',
                            'cursor':'pointer',
                        }}
                        onClick={() => {
                            if (expanded_location_types.includes(location_type_key)) {
                                setExpandedLocationTypes(expanded_location_types.filter((type, ix) => { return type != location_type_key }))
                            } else {
                                setExpandedLocationTypes(expanded_location_types.concat([location_type_key]))
                            }
                        }}
                    >
                        {LocationTypeCounts[location_type_key]} {(LocationTypeCounts[location_type_key] > 1 ? location_type.plural : location_type_key)}
                    </div>)

                if (expanded_location_types.includes(location_type_key)) {

                    // list each location here:
                    var locations = myStore.abm_locations.filter(location => location.location_type == location_type_key)

                    window.locations = myStore.abm_locations
                    locations = locations.sort((a,b) => a.location_name.localeCompare(b.location_name))

                    locations.map((location, location_ix) => {
                        LocationElements.push(
                            <div 
                            key={`lt_${location_type_key}_${location_ix}`}
                            onClick={() => { 
                                console.warn("clicked location", location)
                                myStore.fnCallback((state) => {

                                    const position = Cartesian3.fromDegrees(
                                            location.longitude, 
                                            location.latitude,
                                            (location_type?.fly_to_default_height ? location_type.fly_to_default_height : 100)
                                        )

                                    state.cameraPosition = <CameraFlyTo
                                            duration={3} 
                                            destination={position} 
                                            // orientation={myStore.selected_twin.default_camera_orientation} 
                                            onComplete={() => {
                                                console.log('finished camera change')
                                                myStore.fnSetKeyValue('cameraPosition', null)
                                            }}
                                        />
                                        
                                })
                            }}
                            style={{
                                // borderLeft: `20px solid ${location_type.htmlColour}`,
                                paddingLeft: '25px',
                                fontSize: '.9em',
                                color: '#565656',
                                cursor: 'pointer',
                            }}
                            >
                                {location.location_name}
                            </div>
                        )
                    })

                }
                
            }
        }
        // LocationElements.push(<div>{LocationList}</div>)
        
        
        return () => <Container>
                    {LocationElements}            
        </Container>
    }, [myStore.abm_locations, expanded_location_types])


    const ClearDiaries = useMemo(() => {
        if (! myStore?.abm_diaries) {
            return () => null
        }

        return () => <Button variant='secondary' onClick={() => {
                myStore.fnCallback((state) => {
                    state.abm_diaries = null
                    state.map_layers = {}
                    state.abm_locations = []
                    state.abm_selected.agent = null
                    state.abm_selected.location = null
                    state.selected_areas.sa2 = []
                    state.map_show_clock=false
                })
            }}>
                Clear agents
            </Button>

    },[myStore.abm_diaries])


    const ABMSummary = useMemo(() => () => {
        if (! myStore.abm_diaries) {
            return null
        }

        return <Container>
            <Row>
                <Col>
                    <p>Showing {myStore.abm_diaries.num_agents} agents</p>
                </Col>
            </Row>

        </Container>

    },[myStore.abm_diaries])

    // callback function for store.
    const loadDemographics = (agent_id) => {
        console.log(`loading demographics?`)
        
        if (myStore.abm_selected?.agent.agent_id != agent_id) {
            console.log(`agent ids dont match`)
            return
        }


        console.log(`loadingAgent: ${loadingAgent} v agent_id:${agent_id}`)

        if (loadingAgent == agent_id) {
            console.log(`already loading ${agent_id}`)
            return
        }

        
        setLoadingAgent(agent_id);

        const url = `${config.dev_api_url}/agent/${model_id}/${agent_id}`
        console.log(`loading demographics for agent ${agent_id}`, url)


        axios.get(url, myStore.api_axios_header)
        .then((response) => {
            console.log("Received", response.data)
            myStore.fnCallback((state) => {
                if (state.abm_selected?.agent.agent_id != agent_id) {
                    console.log(`state agent id ${state.abm_selected?.agent.agent_id} doesnt match agent id from demographic request ${agent_id}`)
                    return
                }
                const demographics = response.data?.agents?.[agent_id]
                console.log(`setting demographics `, demographics)
                setAgentDemographics(demographics)
                setLoadingAgent(null)
            })
        })
        .catch((err) => {
            console.warn('error', err)
        })
    }

    const demographicInfo = useMemo(() => {
        console.log("Demographic info:", agent_demographics)

        if (! agent_demographics) {
            return null
        }

        return <p>
            {agent_demographics.age} year old {agent_demographics.ethnicity} {agent_demographics.gender}</p>

    }, [agent_demographics])

    // when agent changes, load agent's demographics.
    useEffect(() => {
        if (! myStore.abm_selected?.agent) {
            setAgentDemographics(null)
            return () => null
        }

        loadDemographics(myStore.abm_selected?.agent.agent_id)

        // also, load location names.
        console.log("positions:", myStore.abm_selected.positions)

    }, [myStore.abm_selected?.agent])
    
    const SelectedAgent = useMemo(() => {
        const agent = myStore.abm_selected?.agent
        if (! agent) {
            return () => null
        }

        return () => <Container>
            <Card>
                <CardBody>
                    <p>
                        Agent {agent?.agent_id} <Button variant='warning' onClick={() => {
                            myStore.fnCallback((state) => {
                                state.abm_selected.agent = null
                                state.abm_selected.location = null
                                setAgentDemographics( null )
                            })
                        }}>Clear</Button>
                    </p>
                    { demographicInfo }
                    { agent.positions.map((position, px) => {
                        if (px % 2 == 0) {
                            return
                        }
                        const location = myStore.abm_diaries['locations'][position.location]

                        var location_type = config.location_types[location.location_type]
                        if (! location_type) {
                            location_type = config.location_types['default']
                            location_type['plural']=location.location_type
                        }

                        return <div key={`agent_position_${px}`} style={{
                            borderLeft: `10px solid ${location_type.htmlColour}`,
                            paddingLeft: '10px'
                            
                            }}>
                            {`${agent.positions[px-1].time.substr(11,5)}-${position.time.substr(11,5)} ${location.location_type}`}
                        </div>
                    })}
                </CardBody>
            </Card>
        </Container>

    },[myStore.abm_selected?.agent, demographicInfo])

    const SA2BusyMessage = useMemo(() => {
        if (! myStore?.working_sa2_point_in_polygon) {
            return null
        }
        return <Alert variant='light'>
            <GearFill className='rotate' /> {myStore?.working_sa2_point_in_polygon}
        </Alert>
    }, [myStore?.working_sa2_point_in_polygon])


    return <div style={{marginTop:'1em'}}>
        <Container>
            <Row style={{marginBottom:'1em'}}>
                <Col>
                    <Button onClick={loadAgents} disabled={loadingAgents}>
                        { loadingAgents && <GearFill className='rotate' /> } Show agents
                    </Button> 
                </Col>
                <Col>
                    <ClearDiaries />
                </Col>
            </Row>
        </Container>
        
        <ABMSummary />

        <SelectedAgent />

        <SelectedLocation />


        { (! myStore.abm_selected?.agent) && 
            <Container>
            <Card>
                <CardBody>
                    <SA2Info />
                    {SA2BusyMessage}
                    <Locations />
                </CardBody>
            </Card>
            </Container>                
        }

    </div>
}


export default LeftMenuABM