import { useParams } from "react-router-dom"
import useDataStore from "../helpers/dataStore"
import { useMemo } from "react"
import { Cartesian3, Color, DistanceDisplayCondition, EntityCluster, HeightReference, NearFarScalar, Rectangle } from "cesium"
import { CameraFlyTo, CustomDataSource, Entity, GeoJsonDataSource } from "resium"
import config from "../helpers/config"
import getBoundingBox from "../helpers/getBoundingBox"
import axios from "axios"


const MapABM = ({model}) => {
    const myStore = useDataStore()

    const SA2Cutout = useMemo(() => {
        if (! myStore.selected_areas.sa2.length) {
            return null
        }


        const sa2 = myStore.selected_areas.sa2[0]
        const sa2_string = sa2.geojson

        // console.log("mapabm, sa2:", sa2)

        const geojson = sa2.geojson
        const cutout_geojson = structuredClone(sa2.geojson)

        // while we're here, lets calc the bounds.

        
        // Calculate the bounding box
        const { minLat, maxLat, minLng, maxLng } = getBoundingBox(geojson);

        // console.log("bounds:", { minLat, maxLat, minLng, maxLng })
        
        // update viewport to new bounds.
        myStore.fnCallback((state) => {
            const lngPadding = (maxLng-minLng)*.1
            const latPadding = (maxLat - minLat) * .1
            const destination = Rectangle.fromDegrees(minLng-lngPadding, minLat-latPadding, maxLng+lngPadding, maxLat+latPadding)
            // console.log('destination', destination)

            const newCameraPosition = <CameraFlyTo
                    duration={3} 
                    destination={destination} 
                    // orientation=null
                    onComplete={() => {
                        console.log('finished camera change')
                        myStore.fnSetKeyValue('cameraPosition', null)
                    }}
                />
            state.cameraPosition = newCameraPosition
        })
    
        // create a cutout - convert to multipolygon
        const [lng_min, lng_max] = [160, 180]
        const [lat_min, lat_max] = [-50, -30]
        const coordinates = [[[lng_min,lat_max],[lng_max,lat_max],[lng_max,lat_min],[lng_min,lat_min]]] 
    
        if (cutout_geojson['type'] == "MultiPolygon") {
            // already a multipolygon..
    
            for (const index1 in cutout_geojson['coordinates']) {
                const island = cutout_geojson['coordinates'][index1]
                coordinates.push(island[0]) // nb - this code will drop cutouts such as lakes from the multipolygon, may need to return to this / check edge cases
            }
    
            cutout_geojson['coordinates'] = [coordinates]
    
        } else {
            // outline
    
            for (const index1 in cutout_geojson['coordinates']) {
                const island = cutout_geojson['coordinates'][index1]
                coordinates.push(island)
            }
     
            cutout_geojson['coordinates'] = [coordinates]
            cutout_geojson['type'] = "MultiPolygon"
        }
    
        const layer_cutout = {
            data: cutout_geojson,
            style: {
                // stroke: Color.RED,
                // stroke: Color.fromAlpha(Color.BLACK, 0),
                fill: Color.fromAlpha(Color.WHITE, 0.4),
                strokeWidth: 0,
                outlineWidth: 0,
            }
        }
       
        return <GeoJsonDataSource 
                key={`geo_cutout_sa2_${sa2.area_code}`}
                name={`geo_cutout_sa2_${sa2.area_code}`}
                data={layer_cutout.data}
                stroke={layer_cutout.style.stroke}
                fill={layer_cutout.style.fill}
                strokeWidth={layer_cutout.style.strokeWidth}
                clampToGround={true}
                onClick={() => {
                    console.log("CLICKED SA2")
                    // myStore.fnSetKeyValue('sa2',false)
                    // myStore.fnSetKeyValue('sa2Locations',false)
                    // myStore.fnSetKeyValue('sim',null)
                    // myStore.fnSetKeyValue('location',null)
                    myStore.fnCallback((state) => {
                        state.selected_areas.sa2 = []
                        state.abm_locations = []
                    })
                }}
            />

    },[myStore.selected_areas.sa2])

    const load_location = (location_id) => {
        console.log(`loading ${location_id}`, myStore?.abm_urls)
        const url = `${config.dev_api_url}/location${( myStore?.abm_urls ? myStore.abm_urls : '' )}/${model}/${location_id}`

        console.log("Loading", url)

        axios.get(url, myStore.api_axios_header)
        .then((response) => {
            const location = response.data
            console.log("LOCATION", location)
            console.log("myStore.abm_selected_location", myStore.abm_selected_location)

            myStore.fnCallback((state) => {
                // check that the data returned from api matches currently selected location
                // e.g. use may have changed selection while the api was working
                if (location.location_id !== state?.abm_selected_location?.location_id) {
                    return 
                }

                console.log("RECIEVED ", location)
                state.abm_selected_location = location
            })


        }).catch((err) => {
            console.warn("ERROR", err)
        })
    }

    const SA2Locations = useMemo(() => {  
        // console.log("SA2_LOCATIONS", myStore?.abm_locations)      

        if (! myStore?.abm_locations) {
            return null
        }

        // return an array full of loctions
        const entities = myStore.abm_locations.map((location, ix) => {
            // const position = Cartesian3.fromDegrees(location.longitude, location.latitude)

            
            const randomMultiplier = 0.00005
            const randomOffsetX = Math.random() * randomMultiplier - randomMultiplier/2
            const randomOffsetY = Math.random() * randomMultiplier - randomMultiplier/2
            const position = Cartesian3.fromDegrees(location.longitude+randomOffsetX, location.latitude+randomOffsetY)

            var location_type_config = config.location_types[location.location_type]

            if (! location_type_config) {
                location_type_config = config.location_types['default']
                console.warn(`UNKNOWN LOCATION TYPE: ${location.location_type}`,location_type_config)
            }

            const point = { 
                // pixelSize: 30, 
                pixelSize: 20 * location_type_config.radius_multiplier, 
                color: Color.fromAlpha(location_type_config.cesiumColour, .6),
                // heightReference: HeightReference.CLAMP_TO_3D_TILE,
                heightReference: HeightReference.CLAMP_TO_TERRAIN,
                // heightReference: HeightReference.RELATIVE_TO_GROUND,
                // disableDepthTestDistance: Number.POSITIVE_INFINITY,
                disableDepthTestDistance: Number.MAX_SAFE_INTEGER
            }


            if (location.location_type == 'household') {
                point.distanceDisplayCondition = new DistanceDisplayCondition(0,3000)
                point.scaleByDistance = new NearFarScalar(400, 1, 3000, 0.1)
            }

            if (location.location_type == 'company') {
                point.distanceDisplayCondition = new DistanceDisplayCondition(0,4000)
                point.scaleByDistance = new NearFarScalar(800, 1, 4000, 0.1)
            }

            return (<Entity
                key={`location_${location.location_id}`}
                position={position}
                point={point}
                clampToGround={true}
                description={`<h3 style='color:${location_type_config.htmlColour}'>${location.location_type}</b></h3><p>${location.location_id}</p>`}
                onClick={(event_cartesian2_position, target) => {
                    myStore.fnSetKeyValue('abm_selected_location', Object.assign({}, location, {status:'loading'}))
                    load_location(location.location_id)
                }}
            />)


        })


        return <CustomDataSource
                key='entity_clustering_datasource'
                clustering={
                        new EntityCluster({
                            enabled: true,
                            pixelRange: 100,
                            minimumClusterSize: 750,
                            clusterPoints: true,
                            clusterLabels: true,
                            show: true,
                        })
                    }
            >
            { entities }
            </CustomDataSource>

    }, [myStore.abm_locations])

    return [
        SA2Cutout,
        SA2Locations,
    ]
}

export default MapABM